import { Component } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LoginData } from '../../../../app/core/classes/loginData.module';
import { RoleCode } from '@app/shared/interfaces/user';
import { LoggingService } from '../../../../app/core/services/log.service';
import { AuthService } from "../../../../app/core/services/auth.service";
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  siteKey = environment.recaptchaSiteKey;

  loginForm: UntypedFormGroup;

  // params from path
  paramId;

  // string for errorMessage service
  msgTranslation;
  errorTranslation;
  errorMessage;

  constructor(
    public settings: SettingsService,
    private formBuilder: UntypedFormBuilder,
    private logger: LoggingService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private translate: TranslateService
  ) {
    // get text message
    //this.errorTranslation =  this.translate.get('error');
    this.translate.get('error').subscribe((text) => {
      this.errorTranslation = text;
    });

    //this.msgTranslation =  this.translate.get('msg');
    this.translate.get('msg').subscribe((text) => {
      this.msgTranslation = text;

      this.paramId = this.route.params.subscribe(params => {
        const id = params['id']; // (+) converts string 'id' to a number
        if (id != null) { this.errorMessage = this.msgTranslation.logout_ok; }
      });
    });

    this.loginForm = this.formBuilder.group({
      'username': [null, Validators.required],
      'password': [null, Validators.required],
      'remember': [false],
      'captcha': [null, Validators.required]
    });

    this.checkLoginData();
  }

  onSubmit(): void {
    const lData = new LoginData(this.loginForm.value.username, this.loginForm.value.password);

    if (this.loginForm.value.remember) {
      localStorage.setItem('loginData', JSON.stringify({ username: lData.username, password: lData.password }));
    } else {
      localStorage.removeItem('loginData');
    }
    
    swal.fire({
      title: this.msgTranslation.loading,
      didOpen: () => {
        swal.showLoading();
      }
    });

    this.authService.login(lData).subscribe(
      (response) => {

        if (response.outcome.success === true) {
          // login successfull

          if (response.data.role == RoleCode.UTENTE_APP) {
            swal.fire(this.msgTranslation.error, this.errorTranslation.forbidden, "error");
            this.settings.sessionExpired();
          } else {
            this.authService.checkFirstLogin().subscribe( response => {
              if( response.data.firstLogin == true ) {
                this.router.navigate(['/activate/' + response.data.idUser]);
              } else {
                this.router.navigate(['/home']);
              }
            })

            swal.close();
          }
        } else {
          swal.fire(this.msgTranslation.error, this.settings.manageErrorMsg(response.outcome), "error");
        }
      },
      (error) => {
        this.logger.log("Error", error, 200);
        this.errorMessage = this.errorTranslation.generic_error + " " + this.errorTranslation.try_again;
        swal.fire(this.msgTranslation.error, this.errorMessage, "error");
      }
    );
  }

  // ---------- PRIVATE METHODS ---------- //

  private checkLoginData(): void {
    const lData: LoginData = JSON.parse(localStorage.getItem('loginData'));

    if (lData) {
      this.loginForm.get('username').setValue(lData.username);
      this.loginForm.get('password').setValue(lData.password);
    }
  }

}
