import { RoleCode } from '@app/shared/interfaces/user';
import { link } from 'fs';

export function getMenu(): any[] {
    const role = localStorage.getItem('role');

    const Home = {
        text: 'Home',
        link: '/home',
        icon: 'fa fa-home'
    };

    const Users = {
        text: 'User',
        link: '/user',
        icon: 'fa fa-user',
        translate: 'menu.user'
    };

    const Contratti = {
        text: 'Contratti',
        link: '/contratti',
        icon: 'fas fa-file-signature', // icon-docs || fas fa-file-contract || fa-file-signature
        translate: 'menu.contratti',
        submenu: [
            {
                text: 'Categorie di contratti',
                link: '/contratti/categorie',
                translate: 'menu.contratti_categorie'
            },
            {
                text: 'Lista contratti',
                link: '/contratti/lista',
                translate: 'menu.contratti_lista'
            },
        ]
    };

    const ContrattiLista = {
        text: 'Contratti',
        link: '/contratti/lista',
        icon: 'fas fa-file-signature', // icon-docs || fas fa-file-contract || fa-file-signature
        translate: 'menu.contratti'
    };

    const Sedi = {
        text: 'Sedi',
        link: '/sedi',
        icon: 'far fa-building',
        translate: 'menu.sedi'
    };

    const Strutture = {
        text: 'Strutture',
        link: '/strutture',
        icon: 'fa fa-cubes',
        translate: 'menu.strutture',
        submenu: [
            {
                text: 'Tipi di strutture',
                link: '/strutture/tipologie',
                translate: 'menu.strutture_tipi'
            },
            {
                text: 'Lista strutture',
                link: '/strutture/lista',
                translate: 'menu.strutture_lista'
            },
        ]
    };

    const Checklist = {
        text: 'Checklist',
        link: '/checklist',
        icon: 'fa fa-check-circle', // icon-docs || fas fa-file-contract || fa-file-signature
        translate: 'menu.checklist',
    };

    const Pianificazioni = {
        text: 'Pianificazioni',
        link: '/pianificazioni',
        icon: 'fa fa-calendar-alt', // icon-docs || fas fa-file-contract || fa-file-signature
        translate: 'menu.pianificazioni',
    };

    const Report = {
        text: 'Report',
        link: '/report',
        icon: 'fas fa-rocket',
        translate: 'menu.report',
        submenu: [
            {
                text: 'Report standard',
                link: '/report/standard',
                translate: 'menu.report_standard'
            },
            {
                text: 'Estrazione KPI',
                link: '/report/kpi',
                translate: 'menu.report_kpi'
            },
            {
                text: 'Controllo pulizie',
                link: '/report/operations',
                translate: 'menu.report_operations'
            }
        ]
    };

    const Analisi = {
        text: 'Analisi',
        link: '/analisi',
        icon: 'fas fa-chart-bar',
        translate: 'menu.analisi',
    };

    let roleMenu = [];

    switch (role) {
        case RoleCode.SUPERADMIN:
            roleMenu = [
                Home,
                Users,
                Contratti,
                Sedi,
                Strutture,
                Checklist,
                Pianificazioni,
                Report,
                Analisi
            ];
            break;
        case RoleCode.COMPANY_ADMIN:
            roleMenu = [
                Home,
                Users,
                Contratti,
                Sedi,
                Strutture,
                Checklist,
                Pianificazioni,
                Report,
                Analisi,
            ];
            break;
        case RoleCode.RESPONSABILE_CONTRATTO:
            roleMenu = [
                Home,
                ContrattiLista,
                Checklist,
                Pianificazioni,
                Analisi,
            ];
            break;
        case RoleCode.VIEWER_CONTRATTO:
            roleMenu = [
                Home,
                ContrattiLista,
                Checklist,
                Analisi
            ];
            break;
        case RoleCode.UTENTE_APP:
            break;
        case RoleCode.VIEWER_LIGHT:
            roleMenu = [
                Analisi,
            ];
            break;
        default:
            break;
    }

    const menu = roleMenu;

    return menu;
}
