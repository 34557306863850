<div class="wrapper">
    <div class="block-center mt-4 wd-xl">
        <!-- START card-->
        <div class="card card-flat">
            <div class="card-header text-center bg-secondary">
                <a href="#">
                    <img class="block-center rounded" src="assets/img/asf-logo.png" alt="Image" style="max-height: 150px;"/>
                </a>
            </div>
            <div class="card-body">
                <p class="text-center py-2">{{'login.title' | translate}}</p>
                <div class="text-danger text-center" *ngIf="errorMessage">{{errorMessage}}</div>
                <form (ngSubmit)="onSubmit()" [formGroup]="loginForm" class="form-validate mb-3" role="form" name="loginForm" novalidate="" >
                    <div class="form-group">
                        <div class="input-group with-focus">
                            <input class="form-control border-right-0" id="email" type="email" name="email" placeholder="{{'base.enter_mail' | translate}}" formControlName="username" />
                            <div class="input-group-append">
                                <span class="input-group-text text-muted bg-transparent border-left-0">
                                    <em class="fa fa-envelope"></em>
                                </span>
                            </div>
                        </div>
                        <div class="text-danger" *ngIf="loginForm.controls['username'].hasError('required') && (loginForm.controls['username'].dirty || loginForm.controls['username'].touched)">This field is required</div>
                    </div>
                    <div class="form-group">
                        <div class="input-group with-focus">
                            <input class="form-control border-right-0" id="password" type="password" name="password" placeholder="{{'base.pwd' | translate}}" formControlName="password" />
                            <div class="input-group-append">
                                <span class="input-group-text text-muted bg-transparent border-left-0">
                                    <em class="fa fa-lock"></em>
                                </span>
                            </div>
                        </div>
                        <div class="text-danger" *ngIf="loginForm.controls['password'].hasError('required') && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)">This field is required</div>
                    </div>
                    <div class="clearfix">
                        <div class="checkbox c-checkbox float-left mt0">
                            <label>
                                <input type="checkbox" name="account_remember" formControlName="remember" />
                                <span class="fa fa-check"></span>{{'login.remember_me' | translate}}</label>
                        </div>
                    </div>
                    <!-- Google reCaptcha v2 -->
                    <div class="recaptcha">
                        <re-captcha [siteKey]="siteKey" formControlName="captcha" required></re-captcha>
                    </div>
                    <button [disabled]="!loginForm.valid" class="btn btn-block btn-primary mt-3" type="submit">{{'login.login' | translate}}</button>
                </form>
                
            </div>
        </div>
        <!-- END card-->
        <div class="p-3 text-center">
            <span>&copy;</span>
            <span>{{ settings.getAppSetting('year') }}</span>
            <span class="mx-2">-</span>
            <span>{{ settings.getAppSetting('name') }}</span>
            <br/>
            <span>{{ settings.getAppSetting('description') }}</span>
            <br/>
            <span>V. {{ settings.getAppSetting('version') }} - V. BE {{settings.getAppSetting('versionBE')}} - {{settings.getAppSetting('releaseDate') }} </span> <!-- | date: 'dd/MM/yyyy' }} </span> -->
        </div>
    </div>
</div>
