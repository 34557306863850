import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';

import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { ActivateComponent } from './pages/activate/activate.component';
import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';

export const routes: Routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
            { path: 'user', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
            { path: 'strutture', loadChildren: () => import('./strutture/strutture.module').then(m => m.StruttureModule) },
            { path: 'sedi', loadChildren: () => import('./sedi/sedi.module').then(m => m.SediModule) },
            { path: 'contratti', loadChildren: () => import('./contratti/contratti.module').then(m => m.ContrattiModule) },
            { path: 'checklist', loadChildren: () => import('./checklist/checklist.module').then(m => m.ChecklistModule) },
            { path: 'pianificazioni', loadChildren: () => import('./pianificazioni/pianificazioni.module').then(m => m.PianificazioniModule) },
            { path: 'report', loadChildren: () => import('./report/report.module').then(m => m.ReportModule) },
            { path: 'analisi', loadChildren: () => import('./analisi/analisi.module').then(m => m.AnalisiModule) },
        ]
    },

    // Not lazy-loaded routes
    { path: 'login', component: LoginComponent },
    { path: 'login/:id', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'activate/:id', component: ActivateComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },

    // Not found
    { path: '**', redirectTo: 'home' }

];
